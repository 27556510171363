import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import NakaLightbox from '../../../components/NakaLightbox'
import Layout from '../../../components/layout'
import MetallicaMenu from './_menu.js'

const Metallica2017TourPage = ({ data }) => (
  <Layout bgClass="clients-metallica">
    <MetallicaMenu pageName="2017_tour" />
    <div className="textPageDetails">
      <p>
      The "Enhanced Experience" VIP poster series for the 2017 US Metallica World Wired tour. Quantities ranged from 800 to 2500 per date, utilizing a group of 23 international artists. 
      </p>
    </div>
    <div className="gallery-container">
    <NakaLightbox images={data.allFile.edges} />
    </div>
  </Layout>
)

Metallica2017TourPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Metallica2017TourPage

export const pageQuery = graphql`
  query Metallica2017TourPageIndexQuery {
    allFile(
      filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativePath: {regex: "/metallica/2017_tour/"}}
      sort: {fields: name}
  ) {
      totalCount
      edges {
        node {
          childImageSharp {
            id
            sizes(maxWidth: 1800) {
              ...GatsbyImageSharpSizes
            }
            fluid {
              srcSet
              src
              originalName
              presentationWidth
              presentationHeight
              originalImg
            }
          }
        }
      }
    }
  }
`
